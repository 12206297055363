@import "../../../../assets/styles/variables";

:root {
  --classification-grid-columns: 1;
  --classification-grid-gap: 20px;

  @media screen and (min-width: $breakpoint-medium) {
    --classification-grid-columns: 2;
  }

  @media screen and (min-width: $breakpoint-xlarge) {
    --classification-grid-columns: 4;
  }
}

.classification-grid {
  display: grid;
  margin: calc(var(--classification-grid-gap) / -2);
  grid-template-rows: auto;
  grid-template-columns: repeat(var(--classification-grid-columns), 1fr);
}
