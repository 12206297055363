@import "../../../../../assets/styles/variables";

.communicationcenterpage-interactive-table-title-bar {
  display: flex;
  gap: 20px;
  align-items: center;

  > * {
    flex: 1;
  }

  &__title {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  &__icon > svg {
    width: 32px;
  }

  &__title-name {
    font-size: $large-font-size;
    font-weight: 500;
    color: $color-grayish-brown;
  }

  /* stylelint-disable declaration-no-important */
  /* disabled stylelint rule, so the less specific styles of
     the button can be overwritten */

  &__new-entry-button {
    margin-bottom: 0 !important;
  }

  &__notice {
    color: $color-grayish-brown;
  }
}

.communicationcenterpage-interactive-table-title-bar--blue {
  background: $color-baby-blue;
  padding: 20px;
}

.communicationcenterpage-interactive-table-title-bar--no-padding-bottom {
  padding-bottom: 0;
}
