$select-background-arrow: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 208 208' style='enable-background:new 0 0 208 208;' xml:space='preserve'%3E%3Cpath id='XMLID_467_' style='fill:%2383878d;' d='M104,121c-1.1,0-2.1-0.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1,0.1-5.7 c1.6-1.5,4.1-1.5,5.7,0.1l22.1,23l22.1-23c1.5-1.6,4.1-1.6,5.7-0.1c1.6,1.5,1.6,4.1,0.1,5.7l-25,26C106.1,120.6,105.1,121,104,121z' /%3E%3C/svg%3E") no-repeat 100% 50%;

.react-select-container {

  .react-select {

    &__control {
      background-color: $color-white;
      border-color: $color-tiara;
      border-radius: $default-border-radius;
      box-shadow: none;
      min-height: 40px;

      &--is-focused {
        border-color: $color-fresh-mint;

        &:hover {
          border-color: $color-fresh-mint;
        }
      }
    }

    &__input input[type=text] {

      .form-group .form-group-input & {
        height: auto;
      }
    }

    &__value-container {
      overflow: hidden; // Prevents large text inputs from overflowing
      padding: 2px $ui-placeholder-padding-left;

      &:hover {
        cursor: text;
      }
    }

    &__placeholder {
      color: $ui-placeholder-color;
    }

    &__indicators {

      .react-select--show-arrow & {
        background: $select-background-arrow;
        width: 40px; // Required for arrow dimensioning
      }
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      display: none; // Is replaced by customized one (see &__indicators)
    }

    &__menu {
      z-index: 100; // Above .card-link (see _misc.scss)
    }

    &__option {
      @include wrap-long-words();

      &--is-focused {
        background: $color-fresh-mint-light;
        color: $color-white;
      }

      &--is-selected {
        background: $brand-color;
        color: $color-white;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
