@import "../../../../assets/styles/variables";

:root {
  --action-card-number-horizontal-spacing: 50px;

  @media screen and (min-width: $breakpoint-xlarge) {
    --action-card-number-horizontal-spacing: 20px;
  }
}

.action-card {
  $action-card-button-height: 44px;
  $action-card-number-size: 48px;
  $action-card-number-icon-spacing: 70px;
  $action-card-button-bubble-radius: 50%;

  padding: calc(var(--action-card-grid-gap, 0) / 2);

  @media screen and (min-width: $breakpoint-xlarge) {
    padding: 0;
  }

  &__container {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    border: 1px solid $color-cash;
    border-radius: $default-border-radius;
    padding-bottom: 20px;
  }

  &__content {
    position: relative;
    margin: 20px var(--action-card-number-horizontal-spacing) 20px;
    text-align: center;
  }

  &__icon {
    height: calc(#{$action-card-number-icon-spacing} * 0.8);
    margin-bottom: 10px;

    &-svg {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__value,
  &__caption {
    font-family: $default-font;
    color: $color-grayish-brown;
  }

  &__value {
    font-weight: $default-font-weight-bold;
  }

  &__caption {
    display: block;
    margin-top: 0.5em;
    font-size: 14px;
    line-height: 1.4em;
    min-height: 40px;
  }

  &__button {
    text-align: center;

    &-link {

      &, &:hover, &:active {
        font-size: 14px;
        font-weight: 500;
        color: $color-grayish-brown;
        text-decoration: underline;
      }
    }
  }
}
