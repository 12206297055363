@import "../../../../assets/styles/variables";

:root {
  --classification-number-horizontal-spacing: 50px;

  @media screen and (min-width: $breakpoint-xlarge) {
    --classification-number-horizontal-spacing: 20px;
  }
}

.classification-tile {
  $classification-button-height: 44px;
  $classification-number-size: 48px;
  $classification-number-size-small: 32px;
  $classification-number-icon-spacing: 70px;
  $classification-number-size-max-width: 2vw;

  padding: calc(var(--classification-grid-gap, 0) / 2);

  &--span-rows {
    grid-row: span 2;
  }

  &__container {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    border: 1px solid $color-cash;
    border-radius: $default-border-radius;
    padding-bottom: $classification-button-height;
  }

  &__content {
    position: relative;
    margin: 40px var(--classification-number-horizontal-spacing) 20px;
    padding-left: $classification-number-icon-spacing;
  }

  &__icon {
    position: absolute;
    left: $classification-number-icon-spacing * 0.5;
    transform: translateX(-50%);
    top: calc(#{$classification-number-size} * 0.1);
    height: calc(#{$classification-number-size} * 0.8);
    width: calc(#{$classification-number-size} * 0.8);

    @media(min-width: $breakpoint-xlarge-min) {
      top: calc(#{$classification-number-size-max-width} * 0.1);
      height: calc(#{$classification-number-size-max-width} * 0.8);
      width: calc(#{$classification-number-size-max-width} * 0.8);
    }

    &-svg {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__value,
  &__caption {
    font-family: $default-font;
    color: $color-grayish-brown;
  }

  &__value {
    font-size: $classification-number-size;

    @media(min-width: $breakpoint-xlarge-min) {
      font-size: $classification-number-size-max-width;
    }

    @media(max-width: $breakpoint-medium-max) {
      font-size: $classification-number-size-small;
    }
  }

  &__value-change {
    padding: 5px 0;
  }

  &__caption {
    display: block;
    margin-top: 0.5em;
    font-size: 14px;
    line-height: 1.4em;
    height: calc(1em * 1.4 * 2);
  }

  &__button {
    display: block;
    height: $classification-button-height;
    line-height: $classification-button-height;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    border-top: 1px solid $color-cash;

    &-link {

      &, &:hover, &:active {
        font-size: 14px;
        font-weight: 500;
        color: $color-grayish-brown;
        text-decoration: underline;
      }
    }
  }

  &--span {
    grid-column: span 2;

    @media(max-width: $breakpoint-small-max) {
      grid-column: inherit;
    }

    .classification-tile__content {

      flex: 1 1 0;

      &:first-child {
        padding-right: 10px;
        border-right: 2px solid $color-cash;
      }

      &:last-child {
        padding-left: 0;
        margin-right: 60px;
      }

      &:first-child:last-child {
        border-right: none;
        position: relative;
        margin: 40px var(--classification-number-horizontal-spacing) 20px;
        padding-left: $classification-number-icon-spacing;
      }

      .classification-tile__value-change {
        position: inherit;
        top: 0;
        right: 0.3em;

        .value-change--positive {
          padding-top: 1em;
        }

        .value-change--negative {
          padding-top: 0.5em;
          padding-bottom: 0.5em;
          padding-left: 1em;
        }
      }

      .classification-tile__caption {
        padding-left: 0.3em;
      }
    }
  }

  &--row-4 {
    grid-row-start: 4;
    grid-row-end: 4;
  }
}
