@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

:root {
  --form-step-number-size: 30px;
  --form-step-progress-line-spacing: 5px;

  @media screen and (min-width: $breakpoint-medium-min) {
    --form-step-number-size: 50px;
  }
}

.form-step {
  display: grid;
  grid-template-areas: "step content" "line content";
  grid-column-gap: 15px;
  grid-template-columns: var(--form-step-number-size) 1fr;
  grid-template-rows: var(--form-step-number-size) 1fr;

  @media screen and (min-width: $breakpoint-medium) {
    grid-column-gap: 30px;
  }

  &__step-number {
    grid-area: step;
    font-size: calc(var(--form-step-number-size) * 0.5);
    width: var(--form-step-number-size);
    height: var(--form-step-number-size);
    line-height: var(--form-step-number-size);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    background: $color-fresh-mint;
    border-radius: $border-radius-rounded;
    font-family: $default-font;
    font-weight: $default-font-weight-bold;

    @media screen and (min-width: $breakpoint-large) {
      line-height: initial;
    }

    &--disabled {
      border: 1px solid $color-silver-chalice;
      color: $color-silver-chalice;
      background: $color-white;
    }

    .icon {
      width: auto;
      height: auto;
      font-size: 0.8em;
    }
  }

  &__progress-line {
    grid-area: line;
    border-right: 1px solid $color-fresh-mint;
    height: calc(100% - 2 * var(--form-step-progress-line-spacing));
    margin: var(--form-step-progress-line-spacing) auto;

    &--disabled {
      border-color: $color-silver-chalice;
    }
  }

  &__content {
    grid-area: content;
    display: flex;
    flex-direction: column;
  }

  &__headline {
    display: flex;
    align-items: center;
    margin: 0 0 0.3em;
    height: var(--form-step-number-size);
    font-family: $default-font;
    font-weight: $default-font-weight-medium;
    padding-right: 10px;

    @media screen and (min-width: $breakpoint-large) {
      padding-right: 20px;
    }

    @media only screen and (max-width: $breakpoint-medium-min) {
      font-size: $large-font-size;
    }

    @media only screen and (max-width: $breakpoint-small-min) {
      font-size: $medium-font-size;
    }
  }

  &__content--disabled &__headline {
    color: $color-silver-chalice;
  }

  &__form {
    margin-bottom: 0;
    padding-right: 10px;

    @media screen and (min-width: $breakpoint-large) {
      padding-right: 20px;
    }
  }

  &__divider {
    margin: 15px 0 25px;
    color: $color-silver-chalice;

    &--no-content {
      margin: 25px 0;
    }
  }
}
