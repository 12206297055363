/* stylelint-disable declaration-no-important */
/* overwritten react-redux-modal-flex colors */

.modal-content {

  h4 {
    color: $color-gray;
  }

  .primary {
    color: $color-white !important;
    background: $color-fresh-mint !important;

    &:hover {
      background-color: $modal-content-primary-hover-color !important;
    }
  }
}

.ReactModal__Overlay {
  background-color: $modal-content-overlay-color !important;
}

/**
Custom Styling of the react-modal
 */

.ReactModal__Content {
  @extend .margin-horizontal-auto; // Center horizontally
  right: 20px;
  bottom: auto !important; // No full height if not necessary
  max-height: calc(100% - 120px);

  @include translate(20px, 20px);

  // On large screens, no fill-width
  @media (min-width: 640px) {
    width: 50%;
  }
}

.modal__overlay {
  // Usually we want the overlay to be above everything, but the tooltips (usually because of tooltips inside the modal)
  z-index: 9000;

  &--display-over-tooltip {
    // The exception to this rule is when we open the modal from a tooltip. (i.e. ZipCode map)
    // In that case the modal should stay on top of the tooltip that triggered it.
    // Note: You can NOT have Tooltipception (A tooltip inside a modal that was triggered by a tooltip)
    z-index: 10000;
  }
}
