@import "../../../assets/styles/variables";

.price-comparison-table-company {
  display: flex;
  flex-direction: column;
  padding: calc(var(--pct-cell-padding) * 2);
  color: $color-grayish-brown;
  height: var(--pct-cell-height);
  box-sizing: border-box;

  &__name,
  &__address,
  &__rating {
    margin-bottom: var(--pct-cell-padding);
    line-height: 1.3em;
  }

  &__name {
    max-height: 2.6em;
    overflow: hidden;
    word-break: break-word;
    hyphens: auto;
  }

  &__rating {
    margin-right: auto;
  }

  &__button {
    margin-top: auto;
    width: 100%;
  }
}
