@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.legacy-browser-notification {
  padding: $default-padding-regular;
  font-size: $medium-font-size;

  @include clearfix;

  &__recommend,
  &__contact {
    padding: $default-padding-regular 0;
  }

  &__contact {
    display: flex;
    flex-direction: column;
  }

  &__phone {
    margin-top: $default-padding-small * 2;
  }

  &__mail {
    margin-top: $default-padding-small;
  }

  .button {
    float: right;
  }
}
