@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/variables";

%podium-rank-three {
  background-image: url("/img/dashboard/Podest_3.svg");
  background-size: contain;

  .podium__rank-avatar {
    margin-bottom: 90px;

    .user-portrait {
      width: 102px;
      height: 105px;
      top: 80px;
      left: 5px;
    }
  }
}

%podium-rank-two {
  background-image: url("/img/dashboard/Podest_2.svg");
  background-size: contain;

  .podium__rank-avatar {
    margin-bottom: 70px;

    .user-portrait {
      width: 112px;
      height: 115px;
      top: 60px;
      left: 5px;
    }
  }
}

%podium-rank-one {
  background-image: url("/img/dashboard/Podest_1.svg");
  background-size: contain;

  .podium__rank-avatar {
    margin-bottom: 40px;

    .user-portrait {
      width: 125px;
      height: 125px;
      top: 30px;
      left: 5px;
    }
  }
}

.podium {
  margin: 0 var(--classification-number-horizontal-spacing);

  &__rank {
    background-position: bottom center;
    background-repeat: no-repeat;
    height: 300px;
    padding: 0;
    margin: 0 5px;
    text-align: center;
    color: $color-white;

    @media(max-width: $breakpoint-small-max) {
      margin: 0;
    }

    * {
      margin-bottom: 10px;
    }

    .podium__rank-avatar {
      margin-left: 4px;
    }

    .podium__rank-amount {
      font-weight: bold;
    }

    &:last-child {
      @extend %podium-rank-three;
    }

    &:first-child {
      @extend %podium-rank-two;
    }

    &:nth-child(2) {
      @extend %podium-rank-one;
    }

    &:only-child {
      @extend %podium-rank-one;
    }
  }
}
