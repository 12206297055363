.uk-dropdown.option-dropdown {
  padding: 0;
  max-width: 150px;

  button {
    padding: 15px 150px 15px 20px;
    width: 100%;
    display: block;

    &:hover {
      background-color: $color-gallery;
    }
  }
}
