@import "../../../../assets/styles/variables";

.timeframe-switcher {
  background-color: $color-gallery;
  padding: 2px;
  margin-bottom: 1em;

  button {
    margin: 0 2px;
  }

  .timeswitcher-active {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  }
}
