input {

  &.uk-input {
    @include hide-placeholder-on-focus();

    &.border-bottom {
      padding-left: 0;
      border-radius: $border-radius-none;
      border-bottom: 1px solid $color-geyser;
    }
  }

  &::-ms-clear { /* stylelint-disable-line selector-pseudo-element-allowed-list */
    // Hide native clear button by MSIE/Edge from Input fields because it breaks ReactJS
    display: none;
  }
}
