@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.price-comparison-table-column {
  /* stylelint-disable-next-line length-zero-no-unit */
  --pct-active-padding: 0px; // css calc needs units! do not remove!

  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: $default-border-radius;

  &__headline {
    text-align: center;

    & + & {
      margin-top: 5px;
    }
  }

  &--active {
    position: relative;
    z-index: 1;

    @media screen and (min-width: $breakpoint-xlarge) {
      --pct-active-padding: 10px;

      margin-top: 0;
      margin-bottom: 0;

      @include box-shadow-outside();
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    background-color: $color-grayish-brown;
    height: 60px;
    color: $color-white;
    border-radius: $default-border-radius $default-border-radius 0 0;
    justify-content: center;
    align-items: center;
  }

  &__row {
    border-color: $color-tiara;
    border-style: solid;
    border-left-width: 1px;
    border-right-width: 1px;
    box-sizing: border-box;

    &:last-of-type {
      border-bottom-width: 1px;
      /* stylelint-disable-next-line sh-waqar/declaration-use-variable */
      border-radius: 0 0 $default-border-radius $default-border-radius;
    }
  }

  @media screen and (min-width: $breakpoint-xlarge) {

    &--active &__header + &__row .price-comparison-table-item {
      height: calc(var(--pct-cell-height) + 2 * var(--pct-active-padding));
    }

    &--active &__row:last-of-type .price-comparison-table-item {
      height: calc(var(--pct-cell-height) + 2 * var(--pct-active-padding));
    }

    &--active &__header + &__row:last-of-type .price-comparison-table-item {
      height: calc(var(--pct-cell-height) + 4 * var(--pct-active-padding));
    }
  }
}
