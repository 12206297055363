@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.universal-file-upload-preview-text-item {
  position: relative;
  width: var(--file-upload-preview-image-size);
  height: 20px;
  line-height: 20px;
  padding-right: 20px;
  margin: 2px 0;

  &__remove {
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%; /* stylelint-disable-line sh-waqar/declaration-use-variable */
    background-color: $color-fresh-mint;
    border: none;
    padding: 0;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &:hover &__remove {
    opacity: 1;
  }

  &__icon {

    @include reset-icon-component-styles;

    font-size: 16px;
    color: $color-white;
  }
}
