@use "sass:math";
@import "./variables";

/* stylelint-disable
declaration-no-important,
selector-pseudo-element-allowed-list,
selector-no-vendor-prefix,
property-no-vendor-prefix,
sh-waqar/declaration-use-variable,
function-comma-space-after,
property-disallowed-list
*/
@mixin box-shadow-outside(
  $offset-x: $default-box-shadow-offset-x,
  $offset-y: $default-box-shadow-offset-y,
  $blur-radius: $default-box-shadow-blur-radius,
  $spread-radius: $default-box-shadow-spread-radius,
  $color: $default-box-shadow-color
) {
  box-shadow: $offset-x $offset-y $blur-radius $spread-radius $color !important;
}

@mixin no-box-shadow() {
  box-shadow: none !important;
}

@mixin text-shadow($color) {
  text-shadow: 1px 1px 2px $color;
}

@mixin text-shadow-large($color: black) {
  text-shadow: 0 0 10px $color;
}

@mixin blur($radius) {
  filter: blur($radius);
}

@mixin backface-visibility($visibility) {
  backface-visibility: $visibility;
}

@mixin keyframes($animation-name) {
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  animation: #{$str};
}

@mixin animation-timing-function($str) {
  animation-timing-function: #{$str};
}

@mixin animation-direction($str) {
  animation-direction: $str;
}

@mixin transition($args...) {
  transition: $args;
}

// generic transform
@mixin transform($transforms) {
  transform: $transforms !important;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

@mixin rotateY($deg) {
  @include transform(rotateY(#{$deg}deg));
}

// scale
@mixin scale($scale...) {
  @include transform(scale($scale...));
}

@mixin scaleX($scale) {
  @include transform(scaleX($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

@mixin translateX($x) {
  @include transform(translateX($x));
}

@mixin translateY($y) {
  @include transform(translateY($y));
}

@mixin translate3d($x, $y, $z) {
  @include transform(translate3d($x, $y, $z));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

//transform origin
@mixin transform-origin($origin) {
  transform-origin: $origin;
}

@mixin background-size($size) {
  background-size: $size;
}

//sets placeholder color for input
@mixin placeholder-color($color) {

  &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: $color;
  }

  &::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
  }

  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $color;
  }
}

// Makes elements unselectable
@mixin no-select() {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

// Removes outline from browsers
@mixin no-outline() {
  outline: none !important;
  -webkit-tap-highlight-color: transparent !important;
}

@mixin pointer-events($events) {
  pointer-events: $events;
}

@mixin no-pointer-events() {
  @include pointer-events(none);
}

@mixin box-sizing($sizing) {
  box-sizing: $sizing;
}

@mixin clearfix() {

  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }

  &:after {
    clear: both;
  }
}

@mixin border-radius($amount) {
  border-radius: $amount;
}

@mixin border-top-left-radius($radius) {
  border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
  border-top-right-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
  border-bottom-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
  border-bottom-left-radius: $radius;
}

@mixin ul-columns($columns) {
  columns: $columns;
  -webkit-columns: $columns;
  -moz-columns: $columns;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  -ms-appearance: none;
  -o-appearance: $value;
  appearance: $value;

  &::-ms-expand {
    display: none;
  }
}

@mixin linear-gradient($gradient...) {
  background: linear-gradient($gradient); /* W3C */
}

@mixin radial-gradient($from, $to) {
  background: radial-gradient(center, circle cover, $from 0%, $to 100%);
}

@mixin removeSearchInputDecoration() {

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

@mixin removeNumberSpinnerButton() {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

@mixin text-decoration-color($color) {
  text-decoration-color: $color;
}

@mixin animation-duration($duration) {
  animation-duration: $duration;
}

@mixin all-sides($gap: 0) {
  top: $gap !important;
  left: $gap !important;
  right: $gap !important;
  bottom: $gap !important;
}

@mixin absolute-full-size($gap: 0) {
  position: absolute;

  @include all-sides($gap);
}

@mixin pseudo-max-size($gap: 0) {
  content: "";

  @include absolute-full-size($gap);
}

@mixin flex-direction($direction) {
  flex-direction: $direction;
}

@mixin flex-grow($prob) {
  flex-grow: $prob;
}

@mixin flex-align($attr) {
  align-items: $attr;
}

@mixin flex-align-self($attr) {
  align-self: $attr;
}

@mixin flex-display() {
  display: flex;
}

@mixin hide-text-on-element() {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}

@mixin hide-placeholder-on-focus() {

  &:focus::-webkit-input-placeholder {
    color: $color-transparent;
  }

  &:focus::-moz-placeholder {
    color: $color-transparent;
  }

  &:focus:-moz-placeholder {
    color: $color-transparent;
  }

  &:focus:-ms-input-placeholder {
    color: $color-transparent;
  }
}

// Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin wrap-long-words() {
  // Remove wordbreak styles as it is incompatible with using hyphens at the same time
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

@mixin single-line() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
Taken from Mozilla Docs:
https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
*/
@mixin line-clamp($no-of-lines) {
  overflow: hidden;
  text-overflow: ellipsis;
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box;
  -webkit-line-clamp: $no-of-lines;  // number of lines to show
  -webkit-box-orient: vertical;
}

@function svg-uri($svg) {
  $encoded: "";
  $slice: 2000;
  $index: 0;
  $loops: ceil(math.div(str-length($svg), $slice));

  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    $chunk: str-replace($chunk, '"', "'");
    $chunk: str-replace($chunk, "<", "%3C");
    $chunk: str-replace($chunk, ">", "%3E");
    $chunk: str-replace($chunk, "&", "%26");
    $chunk: str-replace($chunk, "#", "%23");
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }

  @return "data:image/svg+xml;charset=utf8,#{$encoded}";
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(
      str-slice($string, $index + str-length($search)), $search, $replace
    );
  }

  @return $string;
}

@mixin negative-equalized-horizontal-margins($margin) {
  margin-left: -$margin;
  margin-right: -$margin;
  padding-left: $margin;
  padding-right: $margin;
}

@mixin full-width() {
  $global-medium-large-margin: 40px;
  $global-small-margin: 30px;
  $global-mini-margin: 15px;
  $breakpoint-desktop-view: $container-large-max-width + $global-medium-large-margin * 2;

  @media (min-width: $breakpoint-desktop-view) {
    $outer-distance: calc(-50vw + (#{$container-large-max-width} / 2));

    margin-left: $outer-distance;
    margin-right: $outer-distance;
  }

  @media (min-width: $breakpoint-small-min) and (max-width: $breakpoint-desktop-view) {
    @include negative-equalized-horizontal-margins($global-medium-large-margin);
  }

  @media (min-width: $breakpoint-mini-min) and (max-width: $breakpoint-small-max) {
    @include negative-equalized-horizontal-margins($global-small-margin);
  }

  @media (max-width: $breakpoint-mini-max) {
    @include negative-equalized-horizontal-margins($global-mini-margin);
  }
}

@mixin empto-background-gradient() {
  /* stylelint-disable-next-line unit-allowed-list */
  background-image: linear-gradient(234deg, $color-malachite, $color-fresh-mint);
}

@mixin reset-icon-component-styles() {
  width: auto;
  height: auto;
  line-height: 1;

  &::before {
    margin-left: 0;
    margin-right: 0;
    line-height: 1;
  }
}

// enhances the component to allow displaying the checkmark besides the input field
@mixin with-checkmark-modifier () {

  &--with-checkmark {
    position: relative;
    padding-right: var(--input-checkmark-spacing);
  }
}

@mixin required-dot () {

  &__required-dot {
    background: $color-juniper;
    border-radius: $border-radius-rounded;
    height: 10px;
    left: 0.5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    z-index: 1;

    &--overwritten-value {
      top: 15px;
    }

    &--overwritten-value-with-error {
      top: 15px;
      background: $color-alizarin;
    }

    &--error {
      background: $color-alizarin;
    }
  }
}
