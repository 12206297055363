@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  align-items: center;
  background-color: $color-white-transparent;
  display: flex;
  padding: 0.5em 0;
  width: 100%;
  z-index: 1000;

  @include box-shadow-outside($blur-radius: 15px);

  &__content {
    flex: 1;
    margin: 0 1em;
  }

  &__button {
    margin: 0 1em;
  }
}
