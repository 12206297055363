.success-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__logo {
    width: 120px;
    padding: 50px 0;
  }

  &__text {
    max-width: 400px;
  }
}
