@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.universal-file-upload-modal {

  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    grid-row-gap: 20px;
    padding: 20px;
  }

  &__image-container {
    width: 100%;
    position: relative;
    padding-top: 100%;
    border: 1px solid $color-cash;
  }

  &__image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center center;
  }

  &__icon {

    @include reset-icon-component-styles;

    font-size: 50px;
    display: block;
    text-align: center;
  }

  &__preview-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__comment-value {
    margin: 0;
    color: $color-mineshaft;
  }

  &__confirm-button {
    display: block;
    margin-left: auto;
  }
}
