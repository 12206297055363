@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.universal-file-upload-preview {

  &__label {
    font-weight: 500;
  }

  &__list {
    display: flex;
    flex-flow: row wrap;
    margin-left: calc(-1 * var(--file-upload-preview-gutter-size));
    margin-right: calc(-1 * var(--file-upload-preview-gutter-size));

    &--text {
      flex-direction: column;
    }
  }
}
