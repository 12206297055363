.details-container {
  background-color: $details-container-background-color;
}

.elements-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;

  &__stacked-items {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: $breakpoint-large) {
    grid-template-columns: 1fr calc(50% + var(--input-checkmark-spacing) / 2);
  }
}
