@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

:root {
  --file-upload-preview-image-size: 80px;
  --file-upload-preview-control-size: 25px;
  --file-upload-preview-gutter-size: 10px;
}

.universal-file-upload {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  &--large-previews {
    --file-upload-preview-image-size: 200px;
    --file-upload-preview-control-size: 40px;

    .universal-file-upload-preview-item:only-child {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include with-checkmark-modifier;

  span.input-checkmark {
    right: -8px !important; /* stylelint-disable-line declaration-no-important */
  }

  &__label-container {
    display: flex;
    padding-bottom: $form-field-padding-small;
    padding-top: $form-field-padding;
    align-items: center;
    flex-wrap: wrap;
  }

  &__label {
    font-weight: 500;
    margin-right: 5px;
  }

  &__sub-label {
    color: $color-juniper;
    font-size: 11px;
    font-weight: 300;
  }

  &__input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

  &__button {
    width: 100%;
    font-weight: 500;

    &--above-preview {
      margin-bottom: 20px;
    }

    &--below-preview {
      margin-top: 20px;
    }
  }

  &__error {
    color: $color-alizarin;
    font-weight: $default-font-weight-bold;
    font-size: 1em;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  &__button--above-preview + &__error {
    margin-bottom: 20px;
  }
}
