// THIRD PARTY
@import "../../../node_modules/react-redux-toastr/src/styles/index.scss";
// GENERAL
@import "variables";
@import "../../../node_modules/uikit/src/scss/variables-theme.scss";
@import "mixins";
@import "util/misc";
@import "util/visually-hidden";
@import "fonts";
@import "icons";
// USER-INTERFACE (UI)
@import "ui/button";
@import "ui/checkbox";
@import "ui/datetimepicker";
@import "ui/input";
@import "ui/textarea";
@import "ui/react-table";
@import "ui/react-select";
@import "ui/badge";
@import "ui/headlines";
@import "ui/line-height";
// USER-EXPERIENCE (UX)
@import "ux/forms";
@import "ux/modal";
@import "ux/list";
@import "ux/toastr";
@import "ux/containers";
@import "ux/cards";
@import "ux/maps";
@import "ux/icons";
@import "ux/dropdowns";
@import "ux/tooltip";
@import "ux/collapse";

html, body {
  width: $body-width;
  font-size: $default-font-size;
  font-weight: $default-font-weight;
  color: $body-color;
  background-color: $body-background-color;
  font-family: $default-font;
  height: 100%;
  text-size-adjust: none;

  // Do not apply the min-height on mobile because it breaks the offcanvas-scroll-behaviour
  @media(min-width: $breakpoint-large-min) {
    min-height: $body-min-height;
  }
}

body {

  .uk-tooltip {
    max-width: 400px;
  }
}

#root {
  // Sticky footer, see https://github.com/uikit/uikit/issues/1121
  // UiKit sticky footer does not have the desire effect, since they have a different understanding opf "sticky".
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .page {
    position: relative;
    flex: 1 0 auto;
    width: 100%;
    overflow: hidden;
  }

  .page.remove-padding-bottom {
    padding-bottom: 0;
  }

  footer {
    // This is defined here and not in the Footer component, since the footer should only be sticky in this case
    // and might not in another context. So this styling is related to the page layout, not the footer.
    flex-shrink: 0;
  }
}

p {
  line-height: $global-line-height;
}

.attachment {
  max-height: 100px;
  overflow: hidden;
  margin-right: 5px;

  .filepreview-file {

    .preview-document {
      max-height: 50px;
      background-color: $color-transparent;

      img {
        max-height: 50px;
        width: auto;
        margin: 0 auto;
      }

      i {
        color: $color-juniper;
      }
    }
  }
}

.subtitle {
  margin: 0;
  font-size: 17px;
  font-weight: 300;
}

.margin-regular-top {
  margin-top: $global-regular-margin;
}
