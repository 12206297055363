@font-face {
  font-family: 'fontello';
  src: url('./../../components/common/Fontello/font/fontello.eot?1597744555024');
  src: url('./../../components/common/Fontello/font/fontello.eot?1597744555024#iefix')
      format('embedded-opentype'),
    url('./../../components/common/Fontello/font/fontello.woff?1597744555024')
      format('woff'),
    url('./../../components/common/Fontello/font/fontello.ttf?1597744555024')
      format('truetype'),
    url('./../../components/common/Fontello/font/fontello.svg?1597744555024#fontello')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?73179758#fontello') format('svg');
  }
}
*/

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-alarm:before {
  content: '\e800';
}

.icon-align-center:before {
  content: '\e801';
}

.icon-align-justify:before {
  content: '\e802';
}

.icon-align-left:before {
  content: '\e803';
}

.icon-align-right:before {
  content: '\e804';
}

.icon-anchor:before {
  content: '\e805';
}

.icon-archive:before {
  content: '\e806';
}

.icon-arrow-down:before {
  content: '\e807';
}

.icon-arrow-left:before {
  content: '\e808';
}

.icon-arrow-right:before {
  content: '\e809';
}

.icon-arrow-thin-down:before {
  content: '\e80a';
}

.icon-arrow-thin-left:before {
  content: '\e80b';
}

.icon-arrow-thin-right:before {
  content: '\e80c';
}

.icon-arrow-thin-up:before {
  content: '\e80d';
}

.icon-arrow-up:before {
  content: '\e80e';
}

.icon-article:before {
  content: '\e80f';
}

.icon-backspace:before {
  content: '\e810';
}

.icon-basket:before {
  content: '\e811';
}

.icon-basketball:before {
  content: '\e812';
}

.icon-battery-empty:before {
  content: '\e813';
}

.icon-battery-full:before {
  content: '\e814';
}

.icon-battery-low:before {
  content: '\e815';
}

.icon-battery-medium:before {
  content: '\e816';
}

.icon-bell:before {
  content: '\e817';
}

.icon-blog:before {
  content: '\e818';
}

.icon-bluetooth:before {
  content: '\e819';
}

.icon-bold:before {
  content: '\e81a';
}

.icon-bookmark:before {
  content: '\e81b';
}

.icon-bookmarks:before {
  content: '\e81c';
}

.icon-box:before {
  content: '\e81d';
}

.icon-briefcase:before {
  content: '\e81e';
}

.icon-brightness-low:before {
  content: '\e81f';
}

.icon-brightness-max:before {
  content: '\e820';
}

.icon-brightness-medium:before {
  content: '\e821';
}

.icon-broadcast:before {
  content: '\e822';
}

.icon-browser-upload:before {
  content: '\e823';
}

.icon-browser:before {
  content: '\e824';
}

.icon-brush:before {
  content: '\e825';
}

.icon-calendar:before {
  content: '\e826';
}

.icon-camcorder:before {
  content: '\e827';
}

.icon-camera:before {
  content: '\e828';
}

.icon-card:before {
  content: '\e829';
}

.icon-cart:before {
  content: '\e82a';
}

.icon-checklist:before {
  content: '\e82b';
}

.icon-checkmark:before {
  content: '\e82c';
}

.icon-chevron-down:before {
  content: '\e82d';
}

.icon-chevron-left:before {
  content: '\e82e';
}

.icon-chevron-right:before {
  content: '\e82f';
}

.icon-chevron-up:before {
  content: '\e830';
}

.icon-clipboard:before {
  content: '\e831';
}

.icon-clock:before {
  content: '\e832';
}

.icon-clockwise:before {
  content: '\e833';
}

.icon-cloud-download:before {
  content: '\e834';
}

.icon-cloud-upload:before {
  content: '\e835';
}

.icon-left-open:before {
  content: '\e836';
}

.icon-right-open:before {
  content: '\e837';
}

.icon-cloud:before {
  content: '\e838';
}

.icon-code:before {
  content: '\e839';
}

.icon-contract-2:before {
  content: '\e83a';
}

.icon-contract:before {
  content: '\e83b';
}

.icon-conversation:before {
  content: '\e83c';
}

.icon-copy:before {
  content: '\e83d';
}

.icon-crop:before {
  content: '\e83e';
}

.icon-cross:before {
  content: '\e83f';
}

.icon-crosshair:before {
  content: '\e840';
}

.icon-cutlery:before {
  content: '\e841';
}

.icon-device-desktop:before {
  content: '\e842';
}

.icon-device-mobile:before {
  content: '\e843';
}

.icon-device-tablet:before {
  content: '\e844';
}

.icon-direction:before {
  content: '\e845';
}

.icon-disc:before {
  content: '\e846';
}

.icon-document-delete:before {
  content: '\e847';
}

.icon-document-edit:before {
  content: '\e848';
}

.icon-document-new:before {
  content: '\e849';
}

.icon-document-remove:before {
  content: '\e84a';
}

.icon-document:before {
  content: '\e84b';
}

.icon-dot:before {
  content: '\e84c';
}

.icon-dots-2:before {
  content: '\e84d';
}

.icon-dots-3:before {
  content: '\e84e';
}

.icon-download:before {
  content: '\e84f';
}

.icon-duplicate:before {
  content: '\e850';
}

.icon-enter:before {
  content: '\e851';
}

.icon-exit:before {
  content: '\e852';
}

.icon-expand-2:before {
  content: '\e853';
}

.icon-expand:before {
  content: '\e854';
}

.icon-experiment:before {
  content: '\e855';
}

.icon-export:before {
  content: '\e856';
}

.icon-feed:before {
  content: '\e857';
}

.icon-flag:before {
  content: '\e858';
}

.icon-flashlight:before {
  content: '\e859';
}

.icon-folder-open:before {
  content: '\e85a';
}

.icon-folder:before {
  content: '\e85b';
}

.icon-forward:before {
  content: '\e85c';
}

.icon-gaming:before {
  content: '\e85d';
}

.icon-gear:before {
  content: '\e85e';
}

.icon-graduation:before {
  content: '\e85f';
}

.icon-graph-bar:before {
  content: '\e860';
}

.icon-graph-line:before {
  content: '\e861';
}

.icon-graph-pie:before {
  content: '\e862';
}

.icon-headset:before {
  content: '\e863';
}

.icon-heart:before {
  content: '\e864';
}

.icon-help:before {
  content: '\e865';
}

.icon-home:before {
  content: '\e866';
}

.icon-hourglass:before {
  content: '\e867';
}

.icon-inbox:before {
  content: '\e868';
}

.icon-information:before {
  content: '\e869';
}

.icon-italic:before {
  content: '\e86a';
}

.icon-jewel:before {
  content: '\e86b';
}

.icon-lifting:before {
  content: '\e86c';
}

.icon-lightbulb:before {
  content: '\e86d';
}

.icon-link-broken:before {
  content: '\e86e';
}

.icon-link:before {
  content: '\e86f';
}

.icon-list:before {
  content: '\e870';
}

.icon-loading:before {
  content: '\e871';
}

.icon-location:before {
  content: '\e872';
}

.icon-lock-open:before {
  content: '\e873';
}

.icon-lock:before {
  content: '\e874';
}

.icon-mail:before {
  content: '\e875';
}

.icon-map:before {
  content: '\e876';
}

.icon-media-loop:before {
  content: '\e877';
}

.icon-media-next:before {
  content: '\e878';
}

.icon-media-pause:before {
  content: '\e879';
}

.icon-media-play:before {
  content: '\e87a';
}

.icon-media-previous:before {
  content: '\e87b';
}

.icon-media-record:before {
  content: '\e87c';
}

.icon-media-shuffle:before {
  content: '\e87d';
}

.icon-media-stop:before {
  content: '\e87e';
}

.icon-medical:before {
  content: '\e87f';
}

.icon-menu:before {
  content: '\e880';
}

.icon-message:before {
  content: '\e881';
}

.icon-meter:before {
  content: '\e882';
}

.icon-microphone:before {
  content: '\e883';
}

.icon-minus:before {
  content: '\e884';
}

.icon-monitor:before {
  content: '\e885';
}

.icon-move:before {
  content: '\e886';
}

.icon-music:before {
  content: '\e887';
}

.icon-network-1:before {
  content: '\e888';
}

.icon-network-2:before {
  content: '\e889';
}

.icon-network-3:before {
  content: '\e88a';
}

.icon-network-4:before {
  content: '\e88b';
}

.icon-network-5:before {
  content: '\e88c';
}

.icon-pamphlet:before {
  content: '\e88d';
}

.icon-paperclip:before {
  content: '\e88e';
}

.icon-pencil:before {
  content: '\e88f';
}

.icon-phone:before {
  content: '\e890';
}

.icon-photo-group:before {
  content: '\e891';
}

.icon-photo:before {
  content: '\e892';
}

.icon-pill:before {
  content: '\e893';
}

.icon-pin:before {
  content: '\e894';
}

.icon-plus:before {
  content: '\e895';
}

.icon-power:before {
  content: '\e896';
}

.icon-preview:before {
  content: '\e897';
}

.icon-print:before {
  content: '\e898';
}

.icon-pulse:before {
  content: '\e899';
}

.icon-question:before {
  content: '\e89a';
}

.icon-reply-all:before {
  content: '\e89b';
}

.icon-reply:before {
  content: '\e89c';
}

.icon-return:before {
  content: '\e89d';
}

.icon-retweet:before {
  content: '\e89e';
}

.icon-rocket:before {
  content: '\e89f';
}

.icon-scale:before {
  content: '\e8a0';
}

.icon-search:before {
  content: '\e8a1';
}

.icon-shopping-bag:before {
  content: '\e8a2';
}

.icon-skip:before {
  content: '\e8a3';
}

.icon-stack:before {
  content: '\e8a4';
}

.icon-star:before {
  content: '\e8a5';
}

.icon-stopwatch:before {
  content: '\e8a6';
}

.icon-store:before {
  content: '\e8a7';
}

.icon-suitcase:before {
  content: '\e8a8';
}

.icon-swap:before {
  content: '\e8a9';
}

.icon-tag-delete:before {
  content: '\e8aa';
}

.icon-tag:before {
  content: '\e8ab';
}

.icon-tags:before {
  content: '\e8ac';
}

.icon-thumbs-down:before {
  content: '\e8ad';
}

.icon-thumbs-up:before {
  content: '\e8ae';
}

.icon-ticket:before {
  content: '\e8af';
}

.icon-time-reverse:before {
  content: '\e8b0';
}

.icon-to-do:before {
  content: '\e8b1';
}

.icon-toggles:before {
  content: '\e8b2';
}

.icon-trash:before {
  content: '\e8b3';
}

.icon-trophy:before {
  content: '\e8b4';
}

.icon-upload:before {
  content: '\e8b5';
}

.icon-user-group:before {
  content: '\e8b6';
}

.icon-user-id:before {
  content: '\e8b7';
}

.icon-user:before {
  content: '\e8b8';
}

.icon-vibrate:before {
  content: '\e8b9';
}

.icon-view-apps:before {
  content: '\e8ba';
}

.icon-view-list-large:before {
  content: '\e8bb';
}

.icon-view-list:before {
  content: '\e8bc';
}

.icon-view-thumb:before {
  content: '\e8bd';
}

.icon-volume-full:before {
  content: '\e8be';
}

.icon-volume-low:before {
  content: '\e8bf';
}

.icon-volume-medium:before {
  content: '\e8c0';
}

.icon-volume-off:before {
  content: '\e8c1';
}

.icon-wallet:before {
  content: '\e8c2';
}

.icon-warning:before {
  content: '\e8c3';
}

.icon-web:before {
  content: '\e8c4';
}

.icon-weight:before {
  content: '\e8c5';
}

.icon-wifi:before {
  content: '\e8c6';
}

.icon-wrong:before {
  content: '\e8c7';
}

.icon-zoom-in:before {
  content: '\e8c8';
}

.icon-zoom-out:before {
  content: '\e8c9';
}

.icon-truck:before {
  content: '\e8ca';
}

.icon-processed:before {
  content: '\e8cb';
}

.icon-container-skip:before {
  content: '\e8cc';
}

.icon-document-check:before {
  content: '\e8cd';
}

.icon-plug:before {
  content: '\e8ce';
}

.icon-checked:before {
  content: '\e8cf';
}

.icon-pipe:before {
  content: '\e8d0';
}

.icon-angle-left:before {
  content: '\f104';
}

.icon-angle-right:before {
  content: '\f105';
}

.icon-file-pdf:before {
  content: '\f1c1';
}

.icon-handshake-o:before {
  content: '\f2b5';
}
