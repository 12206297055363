@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";

.universal-file-upload-preview-item {
  position: relative;
  width: var(--file-upload-preview-image-size);
  height: var(--file-upload-preview-image-size);
  margin: var(--file-upload-preview-gutter-size);

  &__mask,
  &__control,
  &__comment-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: $border-radius-rounded;
    margin: 0;
    padding: 0;
    border: none;
  }

  &__filename {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-top: 2px;
    font-size: $small-font-size;
    cursor: default;
    white-space: nowrap;
  }

  &__mask {
    box-sizing: border-box;
    width: var(--file-upload-preview-image-size);
    height: var(--file-upload-preview-image-size);
    border: 1px solid $color-geyser;
  }

  &__image-circle {
    border-radius: $border-radius-rounded;
  }

  &__image {
    display: block;
    object-fit: cover; /* stylelint-disable-line */
    width: var(--file-upload-preview-image-size);
    height: var(--file-upload-preview-image-size);

    &::before {
      content: "";
      display: block;
      width: inherit;
      height: inherit;
      background-color: $color-tiara;
    }
  }

  &__comment-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-fresh-mint-lighter;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid $color-transparent;

    &:hover {
      opacity: 0;
    }
  }

  &__icon {

    @include reset-icon-component-styles;

    font-size: calc(0.5 * var(--file-upload-preview-image-size));
  }

  &__comment-icon {

    @include reset-icon-component-styles;

    color: $color-white;
    margin-top: 0.3em;
    font-size: calc(0.5 * var(--file-upload-preview-image-size));
  }

  &__control {
    box-sizing: border-box;
    height: var(--file-upload-preview-control-size);
    width: var(--file-upload-preview-control-size);
    font-size: calc(0.7 * var(--file-upload-preview-control-size));
    position: absolute;
    background-color: $color-fresh-mint;
    color: $color-white;

    &:hover {
      box-shadow: 0 0 5px 1px $color-black-transparent;
    }

    &--rotate {
      top: 0;
      left: 0;

      .icon-clockwise {
        margin-right: -0.1em; // adjust visually centered
      }
    }

    &--remove {
      top: 0;
      right: 0;
    }

    &-icon {

      @include reset-icon-component-styles;
    }
  }
}
