button { // TODO @Tim: This declaration should be removed once all buttons are migrated to Button components
  cursor: pointer;
  background-color: $color-transparent; // reset color for Firefox
  outline: none;
}

form[data-pending="true"] {
  opacity: 0.8;
  cursor: wait;

  input, button, textarea, select {
    cursor: wait;
  }

  .uk-button {
    opacity: 1;

    &:hover {
      box-shadow: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
