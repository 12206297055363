textarea {
  resize: vertical;
  min-height: $global-control-height;
  max-height: $global-control-height * 4;

  &.uk-textarea {
    padding-top: 12px;

    @include hide-placeholder-on-focus();

    &.border-bottom {
      padding-left: 0;
      border-radius: $border-radius-none;
      border-bottom: 1px solid $color-geyser;
    }
  }
}
