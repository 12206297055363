@import "../../../../assets/styles/variables";

.news-card {
  $card-spacing-horizontal: 20px;

  position: relative;
  border-radius: $default-border-radius;
  border: 1px solid $color-gallery;
  box-sizing: border-box;
  overflow: hidden;

  &__image {
    width: 100%;
    padding-top: 45%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    pointer-events: none;
  }

  &__label {
    display: inline-block;
    box-sizing: border-box;
    max-width: calc(100% - #{2 * $card-spacing-horizontal});
    height: 25px;
    line-height: 25px;
    padding: 0 10px;
    border-radius: $default-border-radius;
    background-color: $color-juniper;
    font-size: 14px;
    font-weight: 500;
    color: $color-white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }

  &--no-image &__label {
    margin: 15px $card-spacing-horizontal 20px;
  }

  &--with-image &__label {
    position: absolute;
    top: 15px;
    left: $card-spacing-horizontal;
    z-index: 1;
  }

  &__date,
  &__headline,
  &__text,
  &__footer {
    box-sizing: border-box;
    padding: 0 $card-spacing-horizontal;
    color: $color-grayish-brown;
    line-height: 1.4;
  }

  &__date {
    font-weight: bold;
    color: $color-tiara;
    margin-bottom: 5px;
  }

  &__headline {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__text {
    margin-bottom: 10px;
  }

  &__footer {
    text-align: center;
    border-top: 1px solid $color-gallery;
    line-height: 45px;
    height: 45px;
    font-weight: bold;
  }

  &__link,
  &__link:hover {
    color: $color-mineshaft;
    text-decoration: underline;
  }
}
