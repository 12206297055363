@import "../../../assets/styles/variables";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/icons";

.form-step-summary-row {
  line-height: 25px;
  color: $color-mineshaft;

  @include wrap-long-words();

  &.label-type-bold {
    font-weight: bold;
  }

  &-value {
    margin-bottom: 15px;

    @media screen and (min-width: $breakpoint-large) {
      margin-bottom: 0;
    }
  }
}

/* clickable rows */

.form-step-summary-rows > a.form-step-summary-rows {
  width: 100%;
  padding-left: 0;
  margin: 0;
}
