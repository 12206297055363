@import "../../../../assets/styles/variables";

:root {
  --number-horizontal-spacing: 50px;

  @media screen and (min-width: $breakpoint-xlarge) {
    --number-horizontal-spacing: 20px;
  }
}

.dashboard-tile {
  $button-height: 44px;
  $number-size: 48px;
  $number-size-small: 32px;
  $number-icon-spacing: 70px;
  $number-size-max-width: 2.1vw;

  padding: calc(var(--classification-grid-gap, 0) / 2);

  &__container {
    box-sizing: border-box;
    height: 100%;
    position: relative;
    border: 1px solid $color-cash;
    border-radius: $default-border-radius;
    padding-bottom: $button-height;
  }

  &__content {
    position: relative;
    margin: 40px var(--number-horizontal-spacing) 20px;
    padding-left: $number-icon-spacing;
  }

  &__icon {
    position: absolute;
    left: $number-icon-spacing * 0.5;
    transform: translateX(-50%);
    top: calc(#{$number-size} * 0.1);
    height: calc(#{$number-size} * 0.8);
    width: calc(#{$number-size} * 0.8);

    @media(min-width: $breakpoint-xlarge-min) {
      top: calc(#{$number-size-max-width} * 0.1);
      height: calc(#{$number-size-max-width} * 0.8);
      width: calc(#{$number-size-max-width} * 0.8);
    }

    &-svg {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__children {
    font-family: $default-font;
    color: $color-grayish-brown;
    display: block;
    margin-top: 0.5em;
    font-size: 14px;
    line-height: 1.4em;
  }

  &__button {
    display: block;
    height: $button-height;
    line-height: $button-height;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    border-top: 1px solid $color-cash;

    &-link {

      &, &:hover, &:active {
        font-size: 14px;
        font-weight: 500;
        color: $color-grayish-brown;
        text-decoration: underline;
      }
    }
  }

  &--row-2 {
    grid-row-start: 2;
    grid-row-end: 2;
  }
}
