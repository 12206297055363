.form-error {
  color: $color-alizarin;

  &.bottom {
    margin-top: 10px;
  }
}

.form-info {
  color: $color-buttercup;
}

.login-container {
  padding: 30px 0;
}

.login-container button {
  width: 100%;
}

.select--cursorpointer {
  cursor: pointer;
}
