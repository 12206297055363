@import "../../assets/styles/variables";

.dashboard {
  @media screen and (min-width: $breakpoint-xlarge) {
    padding: 0 100px;
  }

  &__header {
    margin: 30px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;

    @media screen and (min-width: $breakpoint-medium) {
      margin-top: 30px;
    }

    @media screen and (min-width: $breakpoint-xlarge) {
      margin-top: 50px;
    }

    h2 {
      margin-bottom: 25px;
      flex: 1 1 45%;
      min-width: 45%;
      width: auto;

      @media screen and (min-width: $breakpoint-large) {
        max-width: 75%;
      }
    }

    .button-bar {
      width: 100%;
      flex-wrap: nowrap;

      @media screen and (min-width: $breakpoint-medium) {
        margin-left: auto;
        width: max-content;
      }
    }
  }

  &__contents {
    margin-top: $global-regular-margin;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
    grid-template-rows: auto;

    @media only screen and (min-width: $breakpoint-xlarge) {
      grid-template-columns: repeat(2, 50%);
      grid-template-rows: 1fr;
    }

    &__map {
      grid-column: 1;
      grid-row: 2;

      @media only screen and (min-width: $breakpoint-medium-min) {
        grid-column: 1;
        grid-row: 2;
      }

      @media only screen and (min-width: $breakpoint-xlarge-min) {
        grid-column: 1;
        grid-row: 1;
      }

      .maps-container {
        height: 100%;

        @media only screen and (min-width: $breakpoint-xlarge) {
          width: 98%;
        }

        @media only screen and (max-width: $breakpoint-large-max) {
          height: 330px;
        }

        @media only screen and (max-width: $breakpoint-small-min) {
          height: 430px;
        }

      }
    }
  }

  .classification-tile.caption--faded .classification-tile__caption div.classification-tile__caption-line:last-child {
    color: $color-gray;
    font-size: $small-font-size;
    font-weight: $default-font-weight-light;
  }
}
