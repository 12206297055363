//****************************** COLORS ******************************//
/* stylelint-disable color-no-hex */
$color-fresh-mint: #00bbdd;
$color-fresh-mint-light: rgba($color-fresh-mint, 0.7);
$color-fresh-mint-lighter: rgba($color-fresh-mint, 0.5);
$color-fresh-mint-light-back: rgba($color-fresh-mint, 0.2);
$color-fresh-mint-light-border: rgba($color-fresh-mint, 0.3);
$color-dark-fresh-mint: #00a5c4;
$color-darker-fresh-mint: #008299;
$color-juniper: #779999;
$color-alice-blue: #f6f9fd;
$color-alizarin: #e74c3c;
$color-azalea: #f8cfca;
$color-jade: #00cc66;
$color-malachite: #00ce6d;
$color-green: #90b332;
$color-buttercup: #f39c12;
$color-serenade: #fff5eb;
$color-wheat: #f3d9af;
$color-epd-blue: #3372ce;
$color-epd-blue-20: rgba($color-epd-blue, 0.2);
$color-zircon: #f7f9ff;
$color-baby-blue: #f5f9fd;
$color-light-gray: rgb(247, 247, 247);

// Contrast Color
$color-white: #ffffff;
$color-almost-white: #fafafa;
$color-white-accent: #f5f6f9;
$color-gallery: #ececec;
$color-cash: #ececec;
$color-mercury: #e5e5e5;
$color-geyser: #d7e1e1;
$color-tiara: #becccc;
$color-tiara-25: rgba($color-tiara, 0.25);
$color-silver-chalice: #a5a5a5;
$color-gray: #83878d;
$color-tundora: #484848;
$color-grayish-brown: #4a4a4a;
$color-mineshaft: #252525;
$color-black: #000000;
$color-black-transparent: rgba(0, 0, 0, 0.40);
$color-black-extra-transparent: rgba(0, 0, 0, 0.1);
$color-baby-blue-shade: #e8edf3;

$brand-color: $color-fresh-mint;

//Empto teaser
$empto-teaser-bg-color1: #00bbdd;
$empto-teaser-bg-color2: #01c1c1;
$empto-teaser-bg-color3: #00c990;
$empto-teaser-bg-color4: #00ce6d;

// Special colors:
$color-white-transparent: rgba($color-white, 0.9);
$color-success: $color-jade;
$color-transparent: transparent;
$color-inherit: inherit;
/* stylelint-enable */

//****************************** FONTS ******************************//
/* Oddly, sometimes even with sans-serif we got some text bugs (some text thicker than other in the same string).
Some browsers seem to prefer Sans-Serif better than sans-serif (some naming convention issue perhaps).
Also added serif in case sans-serif or Sans-Serif is invalid in some browser.
TODO: If this bug reappears, better take this issue to @dominik. He might understand this type of behaviour better */
$default-font: "Roboto", sans-serif, Sans-Serif, serif;
$action-font: "Roboto Slab";
$icon-font: "Fontello";

// FONT WEIGHT //
$default-font-weight-light: 300;
$default-font-weight-regular: normal;
$default-font-weight-medium: 500;
$default-font-weight-bold: bold;
$default-font-weight: $default-font-weight-regular;

// FONT STRETCH //
$default-font-stretch-regular: normal;

// FONT STYLE //
$default-font-style-regular: normal;

// LETTER SPACING //
$default-letter-spacing-regular: normal;

// LINE HEIGHT //
$default-line-height-small: 20px;
$default-line-height-regular: normal;
$default-line-height-medium: 40px;

// FONT COLOR //
$default-font-color: $color-black;

// FONT SIZE //
$small-font-size: 13px;
$default-font-size: 14px;
$medium-font-size: 16px;
$large-font-size: 18px;
$xlarge-font-size: 26px;
$xxlarge-font-size: 36px;

// GRID //
$default-grid-gutter-width: 20px;

//****************************** GENERAL ******************************//

// BODY //
$body-width: 100%;
$body-height: $body-width;
$body-min-height: 100vh;
$body-color: $color-black;
$body-background-color: $color-white;
$body-viewport-max-width: 100vw;

// CONTAINER //
$container-narrow-max-width: 365px;
$container-phone-landscape-min-width: 480px;
$container-tablet-landscape-min-width: 768px;
$container-small-max-width: 900px;
$container-large-max-width: 1600px;

// PADDING //
$default-padding-small: 5px;
$default-padding-regular: 15px;
$default-padding-medium: 30px;
$default-padding-large: 40px;

// MARGIN //
$global-small-margin: 5px;
$global-regular-margin: 15px;
$global-medium-margin: 30px;
$global-medium-margin: 40px;
$global-large-margin: 80px;

// ELEMENT-PROPERTIES //
$default-border-radius: 3px;
$border-radius-medium: 15px;
$border-radius-large: 50px;
$border-radius-none: 0;
$border-radius-rounded: 50%;

// CONTENT //
$content-flex: 1 0 0;

// ICONS //
$icon-color: $color-black;

//****************************** MIXINS ******************************//
$default-box-shadow-color: rgba(0, 0, 0, 0.3);
$default-box-shadow-offset-x: 0;
$default-box-shadow-offset-y: 0;
$default-box-shadow-blur-radius: 10px;
$default-box-shadow-spread-radius: 0;

//****************************** BREAKPOINTS (UIkit) ******************************//
$breakpoint-nano: 400px; // Weil es behindert ist
$breakpoint-micro-max: 320px; // Narrow phones portrait
$breakpoint-mini-min: $breakpoint-micro-max + 1px; // Phones portrait
$breakpoint-mini-max: 479px; // Phones portrait
$breakpoint-small-min: $breakpoint-mini-max + 1px; // Phones landscape
$breakpoint-small-max: 767px; // Phones landscape
$breakpoint-medium-min: $breakpoint-small-max + 1px; // Tablets portrait
$breakpoint-medium-max: 959px; // Tablets portrait
$breakpoint-large-min: $breakpoint-medium-max + 1px; // Tablets landscape
$breakpoint-large-max: 1199px; // Tablets landscape
$breakpoint-xlarge-min: $breakpoint-large-max + 1px; // Desktops

// Breakpoint variables UIkit actually uses
$breakpoint-small: $breakpoint-small-min;
$breakpoint-medium: $breakpoint-medium-min;
$breakpoint-large: $breakpoint-large-min;
$breakpoint-xlarge: $breakpoint-xlarge-min;

//****************************** SPECIAL BREAKPOINTS ******************************//
$breakpoint-ipad-landscape: 1024px;

//****************************** UTILITIES ******************************//
$utilities-no-padding: 0;
$utilities-no-margin: 0;
$utilities-no-margin-top: 0;
$utilities-no-margin-bottom: $utilities-no-margin-top;

//****************************** USER INTERFACE (UI) ******************************//

// TRANSITIONS //
$ui-general-transition-time: 0.2s;
$ui-general-transition: #{$ui-general-transition-time} ease;

// REQUIRED-DOT
$ui-required-dot-width: 10px;
$ui-required-dot-height: $ui-required-dot-width;

// PLACEHOLDER //
$ui-placeholder-padding-left: 15px;
$ui-placeholder-color: transparentize($color-black, .5);

//****************************** USER EXPERIENCE (UX) ******************************//

// HEADER //
$header-height: 90px;
$header-height-mobile: 75px;
$header-icon-font-size: 20px;
$header-margin-bottom: 15px;
$header-infobar-height: 40px;

// MENU //
$menu-height: 50px;

// MOBILE MENU //
$menu-mobile-height: calc(var(--page-headerHeight) + 1px); // Add one pixel for border
$menu-mobile-active-point-background-color: rgba($brand-color, 0.23);

// USER-INFO //
$user-info-portrait-height: 50px;

// CONTAINERS //
$details-container-background-color: rgba($color-mineshaft, 0.05);

// MODAL //

// Content //
$modal-content-overlay-color: rgba(46, 46, 46, 0.75);
$modal-content-primary-hover-color: darken($color-fresh-mint, 10%);

// TOASTR //

// Success //
$toastr-success-progressbar-background-color: darken($color-jade, 15%);

// Warning //
$toastr-warning-progressbar-background-color: darken($color-buttercup, 15%);

// Error //
$toastr-error-progressbar-background-color: darken($color-alizarin, 15%);

//****************************** COMPONENTS ******************************//

// INQUIRY-DETAILS //
$inquiry-details-attachments-container-background-color: rgba($color-mineshaft, 0.7);

// MENU-ITEM //
$menu-item-background-color: darken($color-geyser, 15);

// DETAIL-SWITCHER //
$detail-switcher-button-background-color: lighten($color-gallery, 2%);

// CONTROL-EDIT-TOGGLE //
$control-edit-toggle-label-color: darken($color-gray, 30%);

// SQUARED-ICON-BUTTON
$icon-button-squared-size: 50px;

// INFO BANNER //
$info-banner-max-height: 40px;

// INTRO-SECTION //
$hero-image-height-mobile: 45vw;

// HOMEPAGE WASTE PRODUCER
$computer-screen-max-width: 550px;

// FORM-FIELD-PADDING-TOP //
$form-field-padding: 22px;
$form-field-padding-small: 11px;
