@import "../../../../assets/styles/variables";

:root {
  --action-card-grid-columns: 1;
  --action-card-grid-gap: 20px;

  @media screen and (min-width: $breakpoint-medium) {
    --action-card-grid-columns: 2;
  }

  @media screen and (min-width: $breakpoint-xlarge) {
    --action-card-grid-columns: 2;
  }
}

.action-card-grid {
  display: grid;
  margin: calc(var(--action-card-grid-gap) / -2);
  grid-template-rows: auto;
  grid-template-columns: repeat(var(--action-card-grid-columns), 1fr);

  @media screen and (min-width: $breakpoint-xlarge) {

    .action-card:nth-child(even) {
      padding: 10px 20px 10px 5px;
    }

    .action-card:nth-child(odd) {
      padding: 10px 15px 10px 10px;
    }
  }
}
