@import "../../../../assets/styles/variables";

.square-metrics-teaser {
  background-image: linear-gradient(129deg, #00cfab, #0fc3bf, #10c2c0 50%, #35a5f2); /* stylelint-disable-line */
  border-radius: $default-border-radius;
  border: solid 1px $color-cash;
  margin-top: 20px;
  margin-bottom: 10px;
  min-height: 176px;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-areas: "description" "cta" "visual";

  @media screen and (min-width: $breakpoint-medium) {
    grid-template-areas: "visual description" "visual cta";
    grid-template-columns: 235px 1fr;
    background-image: linear-gradient(104deg, #00cfab, #0fc3bf, #10c2c0 50%, #35a5f2); /* stylelint-disable-line */
  }

  @media screen and (min-width: $breakpoint-xlarge) {
    grid-template-areas: "visual description cta";
    grid-template-columns: 285px 1fr 335px;
    background-image: linear-gradient(97deg, #00cfab, #0fc3bf, #10c2c0 50%, #35a5f2); /* stylelint-disable-line */
  }

  &__description {
    grid-area: description;
    color: $color-white;
    line-height: 1.25;
    padding: 20px 20px 0;

    @media screen and (min-width: $breakpoint-medium) {
      padding: 20px 40px;
    }

    @media screen and (min-width: $breakpoint-xlarge) {
      display: flex;
      padding: 20px 0;
      justify-content: center;
      align-items: center;
    }
  }

  &__cta {
    grid-area: cta;
    padding: 20px 20px 10px;

    @media screen and (min-width: $breakpoint-medium) {
      padding: 0 40px 20px 40px;
    }

    @media screen and (min-width: $breakpoint-xlarge) {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 50px;
    }
  }

  &__visual {
    grid-area: visual;
    position: relative;
    pointer-events: none;
    min-height: 160px;
  }

  &__shape {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
  }

  &__logo {
    position: absolute;
    z-index: 1;
    width: 126px;
    height: 45px;
    bottom: 45px;
    left: 73px;
  }
}
