button.icon-button {
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $color-inherit;
  overflow: visible;
  background: none; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  border: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    opacity: 0.3;
  }
}
