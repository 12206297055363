.card-list-card {
  height: 100%; // because of uk-grid-match
  border-radius: $default-border-radius;

  .card-image {
    border-radius: $default-border-radius $default-border-radius 0 0;
    width: 100%;
    max-height: 200px;
    object-fit: cover; /* stylelint-disable-line property-disallowed-list */
  }

  .card-properties {
    color: $color-gray;

    i {
      color: $color-black;
    }

    &__icon {
      font-size: 1.2em;
    }
  }

  .uk-card-body {
    margin-bottom: 70px; // Make space for the absolute bottom button
    line-height: 1.5;
  }
}

.card-body-title {
  position: relative;

  .card-body-title-icon {
    color: $color-tundora;
    cursor: pointer;
    font-size: 18px;
    line-height: 24px;
    margin-top: -3px;
    position: absolute;
    right: 0;
    z-index: 3;
  }
}
