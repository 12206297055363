@import "../../../../assets/styles/variables";

// Hamburgers Sass variables overrides
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 24px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-color: $color-black;
$hamburger-layer-border-radius: 2px;
$hamburger-hover-opacity: 0.9;
$hamburger-types: (slider);

@import "../../../../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
  width: 40px;
  height: 40px;
  transform: translate(8px, 1px);
}
