ul {

  // This is a list with icons as bullets. This is necessary if the text breaks in the next line and we want the
  // text to no float under the icon.

  &.icons-list {

    > li {
      padding-left: 30px;

      > i {
        margin-left: -30px;
        margin-right: 7px;
      }
    }
  }
}
