.status-badge {
  @include box-sizing(border-box);
  @include flex-align(center);
  border-radius: 100%; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  vertical-align: middle;
  background: $color-gray;
  font-size: 0.875rem;
  display: inline-flex;
  justify-content: center;
  padding: 0;
  height: 1em;
  width: 1em;
  min-width: 10px;
  min-height: 10px;

  &--green {
    background-color: $color-green;
  }

  &--gray {
    background-color: $color-silver-chalice;
  }

  &--red {
    background-color: $color-alizarin;
  }

  .status-badge-with-label & {
    margin-right: 0.7em;
    margin-top: -0.2em;
    font-size: 0.7em;
  }

}
