/* stylelint-disable declaration-no-important */
@import "../../../../node_modules/react-redux-toastr/src/styles/variables.scss";

.redux-toastr {

  .toastr {

    .toastr-status {

      &.success {
        background-color: $color-jade;
      }

      &.warning {
        background-color: $color-buttercup;
      }

      &.info {
        background-color: $infoBgColor;
      }

      &.error {
        background-color: $color-alizarin;
      }
    }

    .rrt-left-container {
      width: 60px !important;

      .rrt-holder {
        width: 50px !important;

        .toastr-icon {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    .rrt-middle-container {
      margin-left: 60px !important;
      min-height: 70px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .rrt-text {
        font-size: 15px;
      }
    }

    .rrt-right-container {

      .close-toastr {
        font-size: 20px;
        font-weight: bold;
        color: $color-white;
      }
    }

    &.rrt-success {
      background-color: $color-jade;

      .rrt-progressbar {
        background-color: $toastr-success-progressbar-background-color;
      }
    }

    &.rrt-warning {
      background-color: $color-buttercup;

      .rrt-progressbar {
        background-color: $toastr-warning-progressbar-background-color;
      }
    }

    &.rrt-error {
      background-color: $color-alizarin;

      .rrt-progressbar {
        background-color: $toastr-error-progressbar-background-color;
      }
    }
  }
}
