@import "../../../assets/styles/variables";

@keyframes fadeOutDismissableInfo {

  0% {
    opacity: 1;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.dismissable-info {
  border: solid 1px $color-fresh-mint;
  border-radius: $default-border-radius;
  background-color: $color-fresh-mint-light-back;
  opacity: 1;
  padding: 25px;
  color: $color-grayish-brown;

  &--warning {
    border: solid 1px $color-buttercup;
    background-color: $color-wheat;
  }

  &--fading-out {
    animation-duration: 0.220s;
    animation-name: fadeOutDismissableInfo;
    animation-fill-mode: forwards;
    animation-play-state: running;
    animation-timing-function: ease-out;
  }

  &__title {
    font-weight: 500;
  }

  &__text {
    margin: 10px 0 20px;
  }

  &__button {
    padding-left: 55px;
    padding-right: 55px;
  }
}
