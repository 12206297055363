//****************************** OUR OWN VARIABLES AND MIXINS ******************************//
@import "variables";
@import "mixins";

//****************************** UIKIT VARIABLES OVERRIDE ******************************//
// GLOBAL BACKGROUND COLOR (also used for  .uk-text-...)
$global-primary-background: $color-fresh-mint;
$global-success-background: $color-jade;
$global-warning-background: $color-buttercup;
$global-danger-background: $color-alizarin;
$global-muted-color: $color-gray;

// SELECTION //
$base-selection-background: $brand-color;

// BRAND COLOR //
$brand-color: $color-fresh-mint;

// FONT //
$global-font-family: $default-font;
$global-font-size: $default-font-size;
$base-heading-font-family: $action-font;
$global-xxlarge-font-size: $xxlarge-font-size;

// BORDER //
$global-border: $color-tiara;
$divider-small-border: $color-white;
$border-rounded-border-radius: $default-border-radius;

// BUTTONS //
$button-secondary-color: $color-black;
$button-secondary-hover-color: lighten($color-black, 30%);
$button-secondary-active-color: lighten($color-black, 30%);
$button-text-transform: capitalize;
$button-font-size: 1rem;
$button-primary-disabled-background-color: $color-fresh-mint-light-back;
$button-secondary-disabled-background-color: $color-white;
$button-danger-disabled-background-color: $global-danger-background;
$button-height: 40px;

// SELECT //
$form-select-icon-color: $color-gray;
$inverse-form-select-icon-color: $color-gray;
$internal-form-select-image: svg-uri('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"	 viewBox="0 0 208 208" style="enable-background:new 0 0 208 208;" xml:space="preserve"><path id="XMLID_467_" style="fill:#000;" d="M104,121c-1.1,0-2.1-0.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1,0.1-5.7	c1.6-1.5,4.1-1.5,5.7,0.1l22.1,23l22.1-23c1.5-1.6,4.1-1.6,5.7-0.1c1.6,1.5,1.6,4.1,0.1,5.7l-25,26C106.1,120.6,105.1,121,104,121z"	/></svg>'); /* stylelint-disable-line max-line-length */

// ICONS //
$form-icon-color: $color-gray;
$icon-button-size: $button-height;
$icon-button-font-size: 14px;

// COLOR //
$global-color: $color-mineshaft;
$global-primary-background: $brand-color;
$global-secondary-background: $color-white;

// LINK //
$global-link-color: $brand-color;
$global-link-hover-color: darken($brand-color, 10%);

// CARD //
$card-default-background: $color-geyser;

// CHECKBOX AND RADIO BUTTONS //
$form-radio-background: $color-white;
$form-radio-checked-focus-background: $global-primary-background;
$form-radio-border: $color-tiara;
$form-radio-border-width: 1px;

// MODAL //
$modal-dialog-width: 590px;
// Header
$modal-header-padding-vertical: 15px 60px; // Add this padding to prevent the close icon from overlapping the title.
$modal-header-padding-horizontal: 15px 30px;
$modal-header-border: $color-mercury;

// Title
$modal-title-font-size: 1.3rem;
$modal-title-line-height: 1.7em;

// Footer
$modal-footer-border: none; //$modal-header-border;

// FORM //
$form-placeholder-color: $ui-placeholder-color;

// NAVBAR NAV ITEM //
$navbar-nav-item-font-size: $global-font-size;

// OFFCANVAS //
$offcanvas-bar-padding-vertical: 0;
$offcanvas-bar-padding-horizontal: 0;
$offcanvas-bar-padding-vertical-m: $offcanvas-bar-padding-vertical;
$offcanvas-bar-padding-horizontal-m: $offcanvas-bar-padding-horizontal;
$offcanvas-bar-width: 80%;

// INTERNAL NAV //
$internal-nav-parent-close-image: svg-uri('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve"><path id="XMLID_439_" d="M63,46c0,1.1-0.4,2.1-1.2,2.9l-26,25C35,74.6,34,75,33,75c-1.1,0-2.1-0.4-2.9-1.2c-1.5-1.6-1.5-4.1,0.1-5.7 l23-22.1l-23-22.1c-1.6-1.5-1.6-4.1-0.1-5.7c1.5-1.6,4.1-1.6,5.7-0.1l26,25C62.6,43.9,63,44.9,63,46z"/></svg>'); /* stylelint-disable-line max-line-length */
$internal-nav-parent-open-image: svg-uri('<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve"> <path id="XMLID_467_" d="M46,63c-1.1,0-2.1-0.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1,0.1-5.7c1.6-1.5,4.1-1.5,5.7,0.1l22.1,23l22.1-23 c1.5-1.6,4.1-1.6,5.7-0.1c1.6,1.5,1.6,4.1,0.1,5.7l-25,26C48.1,62.6,47.1,63,46,63z"/> </svg>'); /* stylelint-disable-line max-line-length */
$inverse-nav-parent-icon-color: $color-black;

//****************************** UIKIT VARIABLES AND MIXINS IMPORT ******************************//
@import "../../../node_modules/uikit/src/scss/variables-theme.scss";
@import "../../../node_modules/uikit/src/scss/mixins-theme.scss";

//****************************** UIKIT VARIABLES OVERRIDE ******************************//
$alert-primary-color: darken($global-primary-background, 10%);
$alert-success-color: darken($global-success-background, 10%);
$alert-warning-color: darken($global-warning-background, 10%);
$alert-danger-color: darken($global-danger-background, 10%);

//****************************** UIKIT MIXINS OVERRIDE ******************************//
@mixin hook-button() {
  transition-property: background-color;
  font-family: $default-font;
  font-weight: $default-font-weight-bold;
  min-height: $button-height;

  @include border-radius($border-rounded-border-radius);
  @include transition($ui-general-transition);

  @media only screen and (max-width: $breakpoint-mini-max) {
    padding: 0 20px;
  }

  &.uk-button-primary {
    @include no-box-shadow();
  }

  &.uk-button-increased-font-size {
    font-size: 1.3rem;
  }
}

@mixin hook-button-disabled() {
  cursor: not-allowed;

  &.uk-button-primary {
    background-color: $button-primary-disabled-background-color;
    color: $color-white;

    &.uk-button-lucid {
      background: $global-primary-background;
      color: $color-mercury;
    }
  }

  &.uk-button-lucid {
    opacity: .6;
  }

  &.uk-button-danger {
    background-color: $button-danger-disabled-background-color;
    color: $color-white;
  }

  &.uk-button-secondary {
    background-color: $button-secondary-disabled-background-color;
    color: $global-color;
  }
}

@mixin hook-button-default() {
  border: 1px solid $color-tiara;
}

@mixin hook-button-primary() {
  border: $button-border-width solid transparent;

  &.uk-light {
    background-color: $global-primary-background;
    color: $color-white;
  }
}

@mixin hook-button-hover() {
  @include box-shadow-outside();
}

@mixin hook-icon-button() {
  font-size: $icon-button-font-size;
}

@mixin hook-breadcrumb() {

  li {
    max-width: 100%;

    @include single-line();

    a {
      color: $color-fresh-mint-light;

      &:hover {
        color: $color-fresh-mint;
      }
    }
  }

  /* Move '/' after each child - better on mobile. */

  > li:nth-child(n+2):not(.uk-first-column)::before {
    display: none;
  }

  > li:not(:last-child)::after {
    color: $color-gray;
    content: "/";
    display: inline-block;
    margin: 0 20px;

    @media(max-width: $breakpoint-small-max) {
      margin: 0 10px;
    }
  }
}

@mixin hook-form() {
  border: $form-border-width solid $form-border;
  transition-property: color, background-color, border;
  line-height: normal;

  @include transition(0.2s ease-in-out);
  @include border-radius($border-rounded-border-radius);
}

@mixin hook-form-radio() {
  border: $form-radio-border-width solid $form-radio-border;
  transition-property: background-color, border;
  /* the following property is needed to make sure that the radio button doesn't get squashed when shrinking
   the display width */
  flex-shrink: 0;

  @include border-radius($border-rounded-border-radius);
  @include transition(0.2s ease-in-out);
}

@mixin hook-card() {
  border-radius: $border-rounded-border-radius;

  @include transition(box-shadow 0.1s ease-in-out);
  @include no-box-shadow(); // A plain card without box shadow
}

@mixin hook-card-hover() {
  box-shadow: $card-hover-box-shadow;

  @include transition(all .2s ease);
}

@mixin hook-form-label() {
  display: inline-block;
  margin-bottom: 10px;
  color: $form-label-color;
  font-size: $form-label-font-size;

  @include no-select();
}

@mixin hook-modal-dialog() {
  width: $modal-dialog-width;

  @include border-radius($default-border-radius);

  &:focus {
    @include no-outline();
  }
}

@mixin hook-modal() {
  display: block;
}

@mixin hook-modal-header() {
  border-bottom: $modal-header-border-width solid $modal-header-border;
  border-top-left-radius: $default-border-radius;
  border-top-right-radius: $default-border-radius;
}

@mixin hook-modal-title() {
  font-family: $default-font;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin hook-navbar-dropdown() {
  box-shadow: $navbar-dropdown-box-shadow;
  overflow: hidden;

  @include border-radius($default-border-radius);
}

@mixin hook-tooltip() {
  text-align: center;
  font-size: 13px;
  padding: 10px;
}

//********************* UIKIT OFFCANVAS MOBILE FIX OVERRIDES *********************//
/**
 * Copy UIKit offcanvas styles
 */

$offcanvas-bar-width: 80% !default;
$offcanvas-bar-width-m: 350px !default;

/*
 * Activate slide-out animation
 */

.uk-offcanvas-flip > .uk-offcanvas-content-animation .uk-sticky-fixed {
  left: (-$offcanvas-bar-width);
}

/* Tablet landscape and bigger */
@media (min-width: $breakpoint-medium) {

  .uk-offcanvas-flip > .uk-offcanvas-content-animation .uk-sticky-fixed {
    left: (-$offcanvas-bar-width-m);
  }
}

//****************************** UIKIT THEME IMPORT ******************************//
@import "../../../node_modules/uikit/src/scss/uikit-theme";
