@import "../../../assets/styles/variables";

$dark-theme-color: $color-silver-chalice;

.tippy-popper {
  border-radius: $default-border-radius;
  line-height: 1.5;
  max-width: 600px;

  .tippy-tooltip {
    padding: 15px;
    transform: translateX(0) !important; /* stylelint-disable-line declaration-no-important */

    [x-circle] {
      border-radius: $border-radius-none;
      background-color: $color-transparent;
    }

    &.dark-theme {
      background-color: $dark-theme-color;
    }
  }

  &[x-placement^=top] .tippy-tooltip.dark-theme [x-arrow] {
    border-top-color: $dark-theme-color;
  }

  &[x-placement^=bottom] .tippy-tooltip.dark-theme [x-arrow] {
    border-bottom-color: $dark-theme-color;
  }

  &[x-placement^=left] .tippy-tooltip.dark-theme [x-arrow] {
    border-left-color: $dark-theme-color;
  }

  &[x-placement^=right] .tippy-tooltip.dark-theme [x-arrow] {
    border-right-color: $dark-theme-color;
  }

  p {
    line-height: 20px;

    &:last-child {
      margin-bottom: 0; // No space under last paragraph to make spacing top and bottom in tooltip look the same.
    }
  }
}
