.headline-muted {
  font-family: $default-font;
  color: $color-gray;
  text-transform: uppercase;
  font-weight: bold;
}

.h3-addition {
  font-size: 0.8rem;
  font-weight: bold;
  line-height: 1.4;
  padding: 0.7rem 0;
}
