@import "../../../../assets/styles/variables";

:root {
  --masonry-grid-gutter: #{$default-grid-gutter-width};
}

.masonry-grid {
  margin: 0 calc(var(--masonry-grid-gutter) / -2);

  &--margin-bottom {
    margin-bottom: calc(var(--masonry-grid-gutter) / 2);
  }

  &--margin-top {
    margin-top: calc(var(--masonry-grid-gutter) / 2);
  }

  &__item {
    box-sizing: border-box;
    padding: calc(var(--masonry-grid-gutter) / 2);
  }
}
