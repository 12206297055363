@import "../../../assets/styles/icons";
@import "../../../assets/styles/variables";

.price-comparison-table-item {
  height: var(--pct-cell-height);
  box-sizing: border-box;

  &-checkbox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: calc(var(--pct-cell-padding) + var(--pct-checkbox-size) + var(--pct-active-padding));
    user-select: none;
    cursor: pointer;
    background-color: $color-transparent;
    color: $color-grayish-brown;

    &:focus {
      outline: none;
      border: none;
    }

    &::before {
      content: $icon-checkmark;
      display: flex;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: $color-white;
      top: calc(var(--pct-cell-padding) + var(--pct-active-padding));
      left: calc(var(--pct-cell-padding) + var(--pct-active-padding));
      width: var(--pct-checkbox-size);
      height: var(--pct-checkbox-size);
      border: 1px solid $color-tiara;
      border-radius: $default-border-radius;
      font-size: calc(var(--pct-checkbox-size) - 2px);
      font-family: $icon-font;
      color: $color-transparent;
      transition-duration: 0.1s;
      transition-timing-function: ease-in-out;
      transition-property: border-left-color, color, background-color;

      @media screen and (max-width: $breakpoint-large-max) {
        top: 30px;
        left: 30px;
      }
    }

    &--checked::before {
      border-color: $color-fresh-mint;
      color: $color-white;
      background-color: $color-fresh-mint;
    }

    &--recommended {
      background-color: $color-white-accent;
    }

    &__text {
      display: block;
      text-align: center;

      & + & {
        margin-top: var(--pct-cell-padding);
      }
    }
  }
}
