.ReactTable.table {
  width: 100%;
  border: none;

  .rt-thead {

    &.-header {
      @include no-box-shadow();
    }

    .rt-tr {
      text-align: left;
      color: $color-gray;
    }

    .rt-th,
    .rt-td {

      .rt-resizable-header-content:after {
        display: inline;
        font-family: $icon-font, sans-serif;
        color: $color-black;
        padding-left: 5px;
        font-weight: bold;
      }

      &.-sort-asc {
        // Remove box shadow inset (upper border)
        @include no-box-shadow();

        .rt-resizable-header-content:after {
          content: $icon-chevron-down;
        }
      }

      &.-sort-desc {
        // Remove box shadow inset (upper border)
        @include no-box-shadow();

        .rt-resizable-header-content:after {
          content: $icon-chevron-up;
        }
      }

      &:focus {
        @include no-outline();
      }
    }
  }

  .rt-tbody {

    .rt-tr-group {
      border-bottom: 1px solid $color-gallery;
    }

    .rt-tr {
      border-bottom: solid 1px $color-gray;
    }
  }

  // stylelint-disable no-descending-specificity

  .rt-thead,
  .rt-tbody {

    .rt-th,
    .rt-td {
      border: none;
      line-height: 130%;
      padding: 20px 10px;
      display: flex;
      align-items: center;

      &:first-child {
        padding-left: 5px
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
  // stylelint-enable no-descending-specificity

  .action-buttons {

    .icon-button {
      margin-right: 5px;
      font-size: 1.1em;

      &--checkmark {
        font-size: 1.4em;
      }
    }
  }

  &.-highlight {

    .rt-tbody .rt-tr:not(.-padRow) {
      transition: background-color .2s ease;

      &:hover {
        background-color: $color-gallery;
      }
    }
  }

}
