@import "../../../assets/styles/variables";

@keyframes SwitcherTextFade {

  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    animation-play-state: paused;
  }
}

.switcher {
  $switcher-transition-time: 0.3s;

  position: relative;
  font-size: 34px;
  height: 1em;
  display: inline-flex;
  width: 2.8em;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 0.5em; /* stylelint-disable-line */
  transition: $switcher-transition-time ease-in-out;
  transition-property: background;
  will-change: background;
  user-select: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  &::before {
    content: "";
    width: 1em;
    height: 1em;
    box-sizing: border-box;
    background-color: $color-white;
    border: 2px solid $color-transparent;
    border-radius: 0.5em; /* stylelint-disable-line */
    position: absolute;
    transition: $switcher-transition-time ease-in-out;
    transition-property: left, border;
    will-change: left, border;
  }

  &--active {
    padding-left: 0.3em;
    padding-right: 1.05em;
    background-color: $color-jade;

    &::before {
      border-color: $color-jade;
      left: calc(100% - 1em);
    }
  }

  &--inactive {
    padding-left: 1.05em;
    padding-right: 0.3em;
    background-color: $color-juniper;

    &::before {
      border-color: $color-juniper;
      left: 0;
    }
  }

  &--disabled {
    cursor: default;
  }

  &__status {
    font-size: 0.35em;
    color: $color-almost-white;
    height: 100%;
    display: inline-flex;
    font-weight: 300;
    width: 100%;
    justify-content: center;
    align-items: center;
    opacity: 1;
    animation-duration: $switcher-transition-time;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
    animation-name: SwitcherTextFade;
  }

  &__label {
    min-width: 1px;
    line-height: 1.4;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
