.react-datepicker-wrapper {

  &, .react-datepicker__input-container {
    width: 100%;
  }
}

.react-datepicker-border-stylings {
  border-color: $color-tiara !important; /* stylelint-disable-line declaration-no-important */
}

.react-datepicker {
  font-family: $default-font !important; /* stylelint-disable-line declaration-no-important */
  font-size: $default-font-size !important; /* stylelint-disable-line declaration-no-important */

  @extend .react-datepicker-border-stylings;

  &__triangle {
    border-bottom-color: $color-white !important; /* stylelint-disable-line declaration-no-important */
  }

  &__navigation {
    @include translateY(5px);
  }

  .react-datetimepicker-header-stylings {
    color: $color-mineshaft;
    font-weight: $default-font-weight-bold;
  }

  .react-datepicker-selected-stylings {
    background-color: $brand-color !important; /* stylelint-disable-line declaration-no-important */
  }

  &__month-container {

    .react-datepicker__header {
      padding-top: 15px;
      background-color: $color-transparent;
      border: none;

      .react-datepicker__current-month {
        @extend .react-datetimepicker-header-stylings;
      }

      .react-datepicker__day-names {
        padding-top: 5px;

        .react-datepicker__day-name {
          @media(max-width: $breakpoint-mini-max) {
            width: 1.4rem;
            line-height: 1.4rem;
            margin: 0.1rem;
          }
        }
      }
    }

    .react-datepicker__month {

      .react-datepicker__day {
        width: 2rem;
        line-height: 2rem;

        @include border-radius(50%);

        @media(max-width: $breakpoint-mini-max) {
          width: 1.4rem;
          line-height: 1.4rem;
          margin: 0.1rem;
        }

        &--selected, &--keyboard-selected {
          @extend .react-datepicker-selected-stylings;
        }
      }
    }
  }

  &__time-container {
    @extend .react-datepicker-border-stylings;

    @media(max-width: $breakpoint-mini-max) {
      width: 60px !important; /* stylelint-disable-line declaration-no-important */
    }

    .react-datepicker__header--time {
      background-color: $color-transparent;
      padding: 16px 0 9px 0;
      border: none;

      .react-datepicker-time__header {
        @extend .react-datetimepicker-header-stylings;
      }
    }

    .react-datepicker__time {

      .react-datepicker__time-box {

        @media(max-width: $breakpoint-mini-max) {
          width: 60px !important; /* stylelint-disable-line declaration-no-important */
        }
      }
    }

    .react-datepicker__time-list {

      &-item {
        @media(max-width: $breakpoint-mini-max) {
          padding: 5px 10px 5px 5px !important; /* stylelint-disable-line declaration-no-important */
        }

        &--selected, &--keyboard-selected {
          @extend .react-datepicker-selected-stylings;
        }
      }
    }
  }

  .react-datepicker__time-list {
    padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}
