input {

  &[type=checkbox] {

    &.uk-checkbox {

      & ~ span {
        padding-left: 10px;
        margin-top: -2px;
        cursor: pointer;

        @include no-select();
      }
    }
  }
}
