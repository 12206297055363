@import "../../../assets/styles/icons";
@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

:root {
  --pct-checkbox-size: 16px;
  --pct-cell-height: 190px;
  --pct-cell-padding: 10px;
  --pct-carousel-btn-size: 40px;
  --pct-carousel-btn-offset: 8px;
}

.price-comparison-table {
  display: flex;
  position: relative;
  padding-right: calc(var(--pct-carousel-btn-size) - var(--pct-carousel-btn-offset));
  width: auto;

  &__btn {
    box-sizing: border-box;
    font-size: var(--pct-carousel-btn-size);
    width: 1em;
    height: 1em;
    z-index: 3;
    position: absolute;
    top: 50%;
    border: none;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border-radius: $default-border-radius;
    box-shadow: 0 0 30px 0 rgba(193, 184, 184, 0.69);
    transform: translateY(10px);

    &:disabled {
      cursor: default;
    }

    &::before {
      display: block;
      font-family: $icon-font;
      font-size: 0.6em;
    }

    &--prev {
      left: calc(50% - 24px);

      @media screen and (min-width: $breakpoint-xlarge) {
        left: calc(25% - 12px + var(--pct-carousel-btn-offset));
      }

      &::before {
        content: $icon-chevron-left;
      }
    }

    &--next {
      right: 0;

      &::before {
        content: $icon-chevron-right;
      }
    }
  }

  &-column {

    &:first-of-type {
      margin-right: 48px;
    }

    &:first-of-type,
    &:nth-of-type(3) {
      width: calc(50% - 24px);
    }

    @media screen and (max-width: $breakpoint-large-max) {

      &:nth-of-type(2),
      &:last-of-type {
        display: none;
      }
    }

    @media screen and (min-width: $breakpoint-xlarge) {

      &:first-of-type {
        width: calc(25% - 12px);
        margin-right: 48px;
      }

      &:nth-of-type(2),
      &:last-of-type {
        width: calc(24% - 12px);
      }

      &:nth-of-type(3) {
        margin-left: -1px;
        margin-right: -1px;
        width: calc(27% - 10px);
      }
    }
  }
}
