@import "../../../../../../../assets/styles/variables";

.maklerpremium-agreementform__step4 {

  .radiopanelgroup__label, .radiopanelgroup__container {
    box-sizing: border-box;
    position: relative;
  }

  .radiopanelgroup__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .radio-panel {
    height: 50px;
    width: calc(50% - 10px);
    box-sizing: border-box;
    padding: 10px 0;

    &:last-child {
      margin-left: 20px;
    }

    &__label {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__input {
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0;
    }

    &__content {
      width: 100%;
      margin-left: 40px;
      margin-right: 40px;
    }
  }
}

.go-to-next-step-form-step-button {
  padding-right: 0;
}
