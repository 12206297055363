/* stylelint-disable declaration-no-important */

.cursor-default {
  cursor: default;
}

.no-wrap {
  white-space: nowrap;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.break-word {
  word-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.single-line {
  @include single-line();
}

.two-lines {
  @include line-clamp(2);
}

.single-flex-line {
  flex-wrap: nowrap;
  overflow: hidden;
}

.white-color {
  color: $color-white !important;
}

.white-background-color {
  background-color: $color-white;
}

.margin-horizontal-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

// Full-size card link with valid HTML, see https://stackoverflow.com/questions/796087/make-a-div-into-a-link

.card-link {
  position:absolute;
  width:100%;
  height:100%;
  z-index: 1;

  @include translate(0, 0);
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
