$icon-alarm: "\e800";
$icon-align-center: "\e801";
$icon-align-justify: "\e802";
$icon-align-left: "\e803";
$icon-align-right: "\e804";
$icon-anchor: "\e805";
$icon-archive: "\e806";
$icon-arrow-down: "\e807";
$icon-arrow-left: "\e808";
$icon-arrow-right: "\e809";
$icon-arrow-thin-down: "\e80a";
$icon-arrow-thin-left: "\e80b";
$icon-arrow-thin-right: "\e80c";
$icon-arrow-thin-up: "\e80d";
$icon-arrow-up: "\e80e";
$icon-article: "\e80f";
$icon-backspace: "\e810";
$icon-basket: "\e811";
$icon-basketball: "\e812";
$icon-battery-empty: "\e813";
$icon-battery-full: "\e814";
$icon-battery-low: "\e815";
$icon-battery-medium: "\e816";
$icon-bell: "\e817";
$icon-blog: "\e818";
$icon-bluetooth: "\e819";
$icon-bold: "\e81a";
$icon-bookmark: "\e81b";
$icon-bookmarks: "\e81c";
$icon-box: "\e81d";
$icon-briefcase: "\e81e";
$icon-brightness-low: "\e81f";
$icon-brightness-max: "\e820";
$icon-brightness-medium: "\e821";
$icon-broadcast: "\e822";
$icon-browser-upload: "\e823";
$icon-browser: "\e824";
$icon-brush: "\e825";
$icon-calendar: "\e826";
$icon-camcorder: "\e827";
$icon-camera: "\e828";
$icon-card: "\e829";
$icon-cart: "\e82a";
$icon-checklist: "\e82b";
$icon-checkmark: "\e82c";
$icon-chevron-down: "\e82d";
$icon-chevron-left: "\e82e";
$icon-chevron-right: "\e82f";
$icon-chevron-up: "\e830";
$icon-clipboard: "\e831";
$icon-clock: "\e832";
$icon-clockwise: "\e833";
$icon-cloud-download: "\e834";
$icon-cloud-upload: "\e835";
$icon-left-open: "\e836";
$icon-right-open: "\e837";
$icon-cloud: "\e838";
$icon-code: "\e839";
$icon-contract-2: "\e83a";
$icon-contract: "\e83b";
$icon-conversation: "\e83c";
$icon-copy: "\e83d";
$icon-crop: "\e83e";
$icon-cross: "\e83f";
$icon-crosshair: "\e840";
$icon-cutlery: "\e841";
$icon-device-desktop: "\e842";
$icon-device-mobile: "\e843";
$icon-device-tablet: "\e844";
$icon-direction: "\e845";
$icon-disc: "\e846";
$icon-document-delete: "\e847";
$icon-document-edit: "\e848";
$icon-document-new: "\e849";
$icon-document-remove: "\e84a";
$icon-document: "\e84b";
$icon-dot: "\e84c";
$icon-dots-2: "\e84d";
$icon-dots-3: "\e84e";
$icon-download: "\e84f";
$icon-duplicate: "\e850";
$icon-enter: "\e851";
$icon-exit: "\e852";
$icon-expand-2: "\e853";
$icon-expand: "\e854";
$icon-experiment: "\e855";
$icon-export: "\e856";
$icon-feed: "\e857";
$icon-flag: "\e858";
$icon-flashlight: "\e859";
$icon-folder-open: "\e85a";
$icon-folder: "\e85b";
$icon-forward: "\e85c";
$icon-gaming: "\e85d";
$icon-gear: "\e85e";
$icon-graduation: "\e85f";
$icon-graph-bar: "\e860";
$icon-graph-line: "\e861";
$icon-graph-pie: "\e862";
$icon-headset: "\e863";
$icon-heart: "\e864";
$icon-help: "\e865";
$icon-home: "\e866";
$icon-hourglass: "\e867";
$icon-inbox: "\e868";
$icon-information: "\e869";
$icon-italic: "\e86a";
$icon-jewel: "\e86b";
$icon-lifting: "\e86c";
$icon-lightbulb: "\e86d";
$icon-link-broken: "\e86e";
$icon-link: "\e86f";
$icon-list: "\e870";
$icon-loading: "\e871";
$icon-location: "\e872";
$icon-lock-open: "\e873";
$icon-lock: "\e874";
$icon-mail: "\e875";
$icon-map: "\e876";
$icon-media-loop: "\e877";
$icon-media-next: "\e878";
$icon-media-pause: "\e879";
$icon-media-play: "\e87a";
$icon-media-previous: "\e87b";
$icon-media-record: "\e87c";
$icon-media-shuffle: "\e87d";
$icon-media-stop: "\e87e";
$icon-medical: "\e87f";
$icon-menu: "\e880";
$icon-message: "\e881";
$icon-meter: "\e882";
$icon-microphone: "\e883";
$icon-minus: "\e884";
$icon-monitor: "\e885";
$icon-move: "\e886";
$icon-music: "\e887";
$icon-network-1: "\e888";
$icon-network-2: "\e889";
$icon-network-3: "\e88a";
$icon-network-4: "\e88b";
$icon-network-5: "\e88c";
$icon-pamphlet: "\e88d";
$icon-paperclip: "\e88e";
$icon-pencil: "\e88f";
$icon-phone: "\e890";
$icon-photo-group: "\e891";
$icon-photo: "\e892";
$icon-pill: "\e893";
$icon-pin: "\e894";
$icon-plus: "\e895";
$icon-power: "\e896";
$icon-preview: "\e897";
$icon-processed: "\e8cB";
$icon-print: "\e898";
$icon-pulse: "\e899";
$icon-question: "\e89a";
$icon-reply-all: "\e89b";
$icon-reply: "\e89c";
$icon-return: "\e89d";
$icon-retweet: "\e89e";
$icon-rocket: "\e89f";
$icon-scale: "\e8a0";
$icon-search: "\e8a1";
$icon-shopping-bag: "\e8a2";
$icon-skip: "\e8a3";
$icon-stack: "\e8a4";
$icon-star: "\e8a5";
$icon-stopwatch: "\e8a6";
$icon-store: "\e8a7";
$icon-suitcase: "\e8a8";
$icon-swap: "\e8a9";
$icon-tag-delete: "\e8aa";
$icon-tag: "\e8ab";
$icon-tags: "\e8ac";
$icon-thumbs-down: "\e8ad";
$icon-thumbs-up: "\e8ae";
$icon-ticket: "\e8af";
$icon-time-reverse: "\e8b0";
$icon-to-do: "\e8b1";
$icon-toggles: "\e8b2";
$icon-trash: "\e8b3";
$icon-trophy: "\e8b4";
$icon-upload: "\e8b5";
$icon-user-group: "\e8b6";
$icon-user-id: "\e8b7";
$icon-user: "\e8b8";
$icon-vibrate: "\e8b9";
$icon-view-apps: "\e8ba";
$icon-view-list-large: "\e8bb";
$icon-view-list: "\e8bc";
$icon-view-thumb: "\e8bd";
$icon-volume-full: "\e8be";
$icon-volume-low: "\e8bf";
$icon-volume-medium: "\e8c0";
$icon-volume-off: "\e8c1";
$icon-wallet: "\e8c2";
$icon-warning: "\e8c3";
$icon-web: "\e8c4";
$icon-weight: "\e8c5";
$icon-wifi: "\e8c6";
$icon-wrong: "\e8c7";
$icon-zoom-in: "\e8c8";
$icon-zoom-out: "\e8c9";
$icon-truck: "\e8ca";
$icon-angle-left: "\f104";
$icon-angle-right: "\f105";
$icon-spinner: "\f110";
$icon-container: "\e8cc";
