/* This stylesheet generated by Transfonter (https://transfonter.org) on January 23, 2018 10:25 AM */

@font-face {
  font-family: "Roboto"; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  src: url("./../fonts/Roboto-Light.eot");
  src: url("./../fonts/Roboto-Light.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/Roboto-Light.woff2") format("woff2"),
    url("./../fonts/Roboto-Light.woff") format("woff"),
    url("./../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto"; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  src: url("./../fonts/Roboto-Regular.eot");
  src: url("./../fonts/Roboto-Regular.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/Roboto-Regular.woff2") format("woff2"),
    url("./../fonts/Roboto-Regular.woff") format("woff"),
    url("./../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto"; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  src: url("./../fonts/Roboto-Medium.eot");
  src: url("./../fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/Roboto-Medium.woff2") format("woff2"),
    url("./../fonts/Roboto-Medium.woff") format("woff"),
    url("./../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto"; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  src: url("./../fonts/Roboto-Bold.eot");
  src: url("./../fonts/Roboto-Bold.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/Roboto-Bold.woff2") format("woff2"),
    url("./../fonts/Roboto-Bold.woff") format("woff"),
    url("./../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab"; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  src: url("./../fonts/RobotoSlab-Light.eot");
  src: url("./../fonts/RobotoSlab-Light.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/RobotoSlab-Light.woff2") format("woff2"),
    url("./../fonts/RobotoSlab-Light.woff") format("woff"),
    url("./../fonts/RobotoSlab-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto Slab"; /* stylelint-disable-line sh-waqar/declaration-use-variable */
  src: url("./../fonts/RobotoSlab-Bold.eot");
  src: url("./../fonts/RobotoSlab-Bold.eot?#iefix") format("embedded-opentype"),
    url("./../fonts/RobotoSlab-Bold.woff2") format("woff2"),
    url("./../fonts/RobotoSlab-Bold.woff") format("woff"),
    url("./../fonts/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
